import React from "react";
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Stack,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  Card,
  CardBody,
  Icon,
} from "@chakra-ui/react";
import { FaCloud, FaServer } from "react-icons/fa";

const BackstageOpsSection: React.FC = () => {
  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      variant="outline"
      align={"flex-start"}
      textAlign={'left'}
      background={'transparent'}
      textColor={'white'}
      borderColor={'gray.600'}
    >
      <Stack>
        <CardBody textColor={'white'}>
      <Heading  textAlign={'center'} color="blue.500">
        Backstage.io
      </Heading>
      <Text pt={5}>
        Our experts will guide you through the process of implementing
        Backstage.io in your organization, tailoring the platform to your
        specific needs and integrating it with your existing toolchain and
        infrastructure.
      </Text>
      <Heading pt={3} as="h3" size="md" mb={2} color="blue.100">
        Backstage.io Plugin Development
      </Heading>
      <Text fontSize ="md">
        Leverage our team's expertise to develop custom plugins for
        Backstage.io, extending its functionality and seamlessly integrating
        with your organization's unique tools and workflows.
      </Text>
      <Heading pt={3} as="h3" size="md" mb={2} color="blue.100" >
        Backstage.io Training and Support
      </Heading>
      <Text fontSize="md">
        Our comprehensive training programs and dedicated support ensure your
        team can effectively utilize Backstage.io, maximizing its potential and
        driving developer productivity and collaboration.
      </Text>
      </CardBody>
      </Stack>
    </Card>
  );
};

export default BackstageOpsSection;
