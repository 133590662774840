import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  GridItem,
  Flex,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import { AppRoutes } from "./routes/routes";
import MenuBar from "./components/menu";

export const App = () => (
  <ChakraProvider >
      <AppRoutes></AppRoutes>
  </ChakraProvider>
);
