import React from "react";
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Stack,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  Container,
  Icon,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { FaCloud, FaServer } from "react-icons/fa";

const DevOpsSection: React.FC = () => {
  return (
    <Card 
    direction={{base: 'column', sm: 'row'}}
    overflow='hidden'
    variant='outline'
    color="gray.800"
    background={'transparent'}
    borderColor={'gray.600'}
    >
    <Stack  spacing={4}>
      <CardBody textAlign={'left'} color="gray.800" textColor={'white'}>
    <Heading color="blue.500" textAlign={'center'}>
      DevOps & Platform Engineering 
    </Heading>
    <Text py={5}>
      Our experts will streamline your software delivery pipeline by implementing industry-leading DevOps tools and automation frameworks, enabling continuous integration, continuous deployment, and automated testing.
    </Text>
    <Heading as="h3" size="md" mb={2} color="blue.100">
      Cloud-Native Platform Engineering
    </Heading>
    <Text fontSize="md">
      We design and build scalable, resilient, and self-healing cloud-native platforms tailored to your organization's needs, leveraging technologies like Kubernetes, Istio, and Knative for efficient application deployment and management.
    </Text>
    <Heading pt={3} as="h3" size="md" mb={2} color="blue.100">
      Observability and Monitoring Solutions
    </Heading>
    <Text fontSize="md">
      Gain deep insights into your applications and infrastructure with our comprehensive observability and monitoring solutions, enabling proactive identification and resolution of issues, ensuring high availability and performance.
    </Text>
    </CardBody>
  </Stack>
  </Card>
    
            
  );
};

export default DevOpsSection;
