import React from "react";
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Stack,
  Image,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  Icon,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "@chakra-ui/react";
import { FaCloud, FaServer } from "react-icons/fa";

const CloudOpsCard: React.FC = () => {
  return (
    <Card 
    direction={{base: 'column', sm: 'row'}}
    variant='outline'
    align={'flex-start'}
    background={'transparent'}
    textColor="white"
    borderColor={'gray.600'}
    >
      <Stack >
      <CardBody  textAlign={'left'} padding={4} textColor="white">
        <Heading textAlign={'center'} color="blue.500">Cloud Integration & Automation</Heading>
              <Text pt={5} >
                Our experts analyze your existing cloud infrastructure, identify
                bottlenecks and inefficiencies, and implement best practices to
                ensure optimal performance, scalability, and cost-effectiveness.
              </Text>
              <Heading pt={3} as="h3" size="md" mb={2} color="blue.100">
                Infrastructure as Code (IaC) Implementation
              </Heading>
              <Text fontSize="md">
                We help you adopt IaC principles, automating the provisioning
                and management of your cloud resources through code, enabling
                consistent and repeatable deployments across environments.
              </Text>
              <Heading pt={3} as="h3" size="md" mb={2} color="blue.100">
                Cloud-Native Application Development
              </Heading>
              <Text fontSize="md">
                Our team of cloud-native experts will guide you in designing,
                building, and deploying applications that fully leverage the
                benefits of cloud computing, ensuring seamless scalability,
                resilience, and efficient resource utilization.
              </Text>
      </CardBody>
      </Stack>
    </Card>
  );
};

export default CloudOpsCard;
