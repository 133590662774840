import * as React from 'react'
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import  Home from "../pages/home";
import LandingPage from '../pages/home';
import { About } from "../pages/about"


// const AppRoutes: 

export const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path ="/test" element={<LandingPage/>}/>
                <Route path ="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
            </Routes>
        </Router>
    )
}